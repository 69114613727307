import { Container } from "../shared"

import React, { useEffect, useState } from "react"
import { FaGithub, FaExternalLinkAlt, FaCodepen } from "react-icons/fa"

// import { Container } from "../shared/Container/container"

import { portfolios } from "../../data"

import "./portfolio.css"
// import { Button } from "../shared"
// import { Link } from "gatsby"

const Portfolios = () => {

  const portfolioCategoriesName = Object.keys(portfolios.All)

  const [selectedPortfolio, setSelectedPortfolio] = useState("All")

  const [handlePortfolioMap, setHandlePortfolioMap] = useState([])

  useEffect(() => {
    if (selectedPortfolio === "All") {
      let allPortfolios = []
      for (let el of Object.values(portfolios.All)) {
        allPortfolios.push(...el)
      }
      setHandlePortfolioMap(allPortfolios)
    } else {
      setHandlePortfolioMap(portfolios.All[selectedPortfolio])
    }
  }, [selectedPortfolio])

  return (
    // <div id="" className="portfolio-area">
      <Container>
        <div className="portfolios" style={{marginBottom: '100px'}}>
          <ul className="portfolio-nav mouseEvents">
          <li
              onClick={() => setSelectedPortfolio("All")}
              className={selectedPortfolio === "All" ? "active" : ""}
            >
              All
            </li>
            {portfolioCategoriesName.map(name => (
              <li
                onClick={() => setSelectedPortfolio(name)}
                className={name === selectedPortfolio ? "active" : ""}
                key={name}
              >
                {name}
              </li>
            ))}
          </ul>

          <div className="portfolio-items">
            {handlePortfolioMap &&
              handlePortfolioMap.map((portfolio, idx) => (
                <div key={idx} className="portfolio">
                  <div className="portfolio-img mouseEvents">
                    <img alt={portfolio.name} src={portfolio.image} />
                  </div>
                  <div className="details">
                    <h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={portfolio.demo}
                      >
                        {portfolio.name}
                      </a>
                    </h4>
                    <p>{portfolio.description}</p>
                    <div className="links">
                      <ul>
                        {portfolio.source && (
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={portfolio.source}
                            >
                              <FaGithub />
                            </a>
                          </li>
                        )}
                        {portfolio.demo && (
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={portfolio.demo}
                            >
                              <FaExternalLinkAlt />
                            </a>
                          </li>
                        )}
                        {portfolio.codepen && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={portfolio.codepen}
                          >
                            <FaCodepen />
                          </a>
                        </li>
                      )}
                      </ul>
                    </div>
                    <ul className="portfolio-tools">
                      {portfolio.tools.map(tool => (
                        <li key={tool}>{tool}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    // </div>
  )
}

export { Portfolios }
