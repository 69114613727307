import React from "react"

import { Layout, SEO } from "../components/shared"
import { Portfolios } from "../components/Portfolio/Portfolios"

const PortfolioPage = props => (
  <Layout locationPath={props.location.pathname}>
    <SEO title="Mostafa's Projects | React | Node | MongoDB | Express | Vanilla | mdmostafa.com" />
    <Portfolios />
  </Layout>
)

export default PortfolioPage;
